import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '@core/services/utils/utils.service';
import { AppUrls } from '@config/app-urls.config';
import { EnviromentService } from '../services/environment/environment.service';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error/error.service';
import { HeaderCleanerInterceptor } from './header-cleaner.interceptor';
import { ModalService } from '@shell/modal/modal.service';

/**
 * Interceptor for handling http errors
 */
@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly utils: UtilsService,
    private readonly errorService: ErrorService,
    private router: Router,
    private envConfig: EnviromentService,
    private modalService: ModalService
  ) {}

  /**
   * This method will get the error parsing the JSON in string format, there is additional control in order to control double stringed error
   * @param error: the error in string format containing the JSON
   */
  private getErrorWithJSONParse(error): any {
    const parsedError = error && !(error instanceof Object) && !error.startsWith('<') ? JSON.parse(error) : error;

    return !(parsedError instanceof Object) ? this.getErrorWithJSONParse(parsedError) : parsedError;
  }

  mapErrors(error: Error | HttpErrorResponse | any, request: HttpRequest<any>): Error | HttpErrorResponse {
    const endpoint = this.utils.matchRequestEndpoint(request);
    const errorMessage: any = error.error ? error.error : null;
    const errorCode: string = error.error?.errorCode || error.error?.internalCode;

    const custom = {
      customMessage:
        errorMessage && errorCode
          ? this.utils.errorsCatalogue(errorCode)
          : (endpoint && endpoint.restOptions && endpoint.restOptions.errorDefault) || 'COMMON.ERROR.GENERIC'
    };
    return { ...custom, ...error };
  }

  /**
   * Helper function for customizing the error handling
   *
   * It considers the error type in order to assign the proper kind of logging and handling
   *
   * @param error: Error | HttpErrorResponse
   */
  traceError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        // Usually frontend errors such as issues on RxJS
        console.warn('Error: ', error);
      } else {
        // Backend errors
        console.error('Error: ', error);
      }
    } else {
      // Anything else
      console.log('Error: ', error);
    }
  }

  /**
   * Show error modal
   * @param error
   */
  showError(error: Error | HttpErrorResponse | any) {
    this.modalService.triggerErrorModal('COMMON.ERROR.GENERIC_TITLE', error.customMessage);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      console.log(`It appears that you don't  have internet connection`);
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (req?.headers?.get(HeaderCleanerInterceptor.MOCKHEADERKEY)) {
          return this.manageError(error, req);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private manageError(error: any, req: HttpRequest<any>): Observable<never> {
    const isConfigEndpoint = req.url.indexOf(AppUrls.Config) !== -1;
    const baseSecurity = this.envConfig.get().security.sccConfiguration.basePath;
    const expiration = this.envConfig.get().security.sccConfiguration.expirationEndpoint.url;
    const URLSecurity: string = baseSecurity + expiration;
    const baseUrl = this.envConfig.get().app.rest.PS.baseUrl;
    const headerMod = this.envConfig.get().app.rest.PS.endpoints.HeaderMod.url;
    const URLNcHeaderMod: string = baseUrl + headerMod;
    const login = this.envConfig.get().app.rest.PS.endpoints.Login.url;
    const URLLogin: string = baseUrl + login;
    const status: string = error['status'].toString();
    const httpError: HttpErrorResponse = error as HttpErrorResponse;
    const customError = req.url.indexOf('accesses/article') !== -1;
    const contentError = req.url.includes('/accesses/content/');

    if (req.url === URLLogin || req.url === URLSecurity || req.url === URLNcHeaderMod) {
      const message = 'Error de acceso / Access error';
      this.router.navigate([AppUrls.AppError], { queryParams: { error: status, message: message } });
      return throwError(error);
    } else if (customError && (httpError.status === 400 || httpError.status === 422)) {
      return throwError(error);
    } else if (contentError && httpError.status === 404) {
      return throwError(error);
    } else {
      error = !isConfigEndpoint ? this.mapErrors(error, req) : error;
      this.traceError(error);
      this.showError(error);
    }
  }
}
