/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContentProviderInterResponseDto } from '../models/content-provider-inter-response-dto';
import { ContentProviderResponse } from '../models/content-provider-response';
import { DocumentDto } from '../models/document-dto';


/**
 * Content provider
 */
@Injectable({
  providedIn: 'root',
})
export class ContentService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getContent
   */
  static readonly GetContentPath = '/accesses/contents/{contentId}/{language}';

  /**
   * Get content.
   *
   * Returns content by id and language
   *
   * This method provides customization options.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: {
    contentId: string;
    language: string;

  }): Observable<ContentProviderResponse> {

    const req = new SilverRequestBuilder(ContentService.GetContentPath, 'get');
    if (params) {
      req.path('contentId', params.contentId, {});
      req.path('language', params.language, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ContentProviderResponse;
      })
    );
  }

  /**
   * Get content.
   *
   * Returns content by id and language
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getContent$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: {
    contentId: string;
    language: string;

  }): Observable<ContentProviderResponse> {

    return this.getContent$Response(params).pipe(
      map(r => r as ContentProviderResponse)
    );
  }

  /**
   * Path part for operation getContentProviderInter
   */
  static readonly GetContentProviderInterPath = '/accesses/content/{contentName}/{language}';

  /**
   * Get content.
   *
   * Returns content by name and language
   *
   * This method provides customization options.
   * To access only the response body, use `getContentProviderInter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentProviderInter$Response(params: {
    contentName: string;
    language: string;

  }): Observable<ContentProviderInterResponseDto> {

    const req = new SilverRequestBuilder(ContentService.GetContentProviderInterPath, 'get');
    if (params) {
      req.path('contentName', params.contentName, {});
      req.path('language', params.language, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ContentProviderInterResponseDto;
      })
    );
  }

  /**
   * Get content.
   *
   * Returns content by name and language
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getContentProviderInter$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getContentProviderInter(params: {
    contentName: string;
    language: string;

  }): Observable<ContentProviderInterResponseDto> {

    return this.getContentProviderInter$Response(params).pipe(
      map(r => r as ContentProviderInterResponseDto)
    );
  }

  /**
   * Path part for operation getDocument
   */
  static readonly GetDocumentPath = '/accesses/contents/document-download';

  /**
   * Download ContentProviderInternationalization file
   *
   * This method provides customization options.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: {

    /**
     * The content provider identifier
     */
    id: number;

    /**
     * The language ISO value
     */
    language: string;

    /**
     * Document selector
     */
    document: 'BANNER' | 'BUTTON1' | 'BUTTON2';

  }): Observable<Array<DocumentDto>> {

    const req = new SilverRequestBuilder(ContentService.GetDocumentPath, 'post');
    if (params) {
      req.query('id', params.id, {});
      req.query('language', params.language, {});
      req.query('document', params.document, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Array<DocumentDto>;
      })
    );
  }

  /**
   * Download ContentProviderInternationalization file
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getDocument$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: {

    /**
     * The content provider identifier
     */
    id: number;

    /**
     * The language ISO value
     */
    language: string;

    /**
     * Document selector
     */
    document: 'BANNER' | 'BUTTON1' | 'BUTTON2';

  }): Observable<Array<DocumentDto>> {

    return this.getDocument$Response(params).pipe(
      map(r => r as Array<DocumentDto>)
    );
  }

}
