import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContentProviderInterResponseDto, WebContentResponse } from '@app/core/api/SILVER/models';
import { ActionLogService, ContentService } from '@app/core/api/SILVER/services';
import { CompAccess } from '@app/core/services/routeDinamyc/mbox.model';
import { TranslationsService } from '@app/core/services/translations/translations.service';
import { UtilsService } from '@app/core/services/utils/utils.service';
import { ModalService } from '@app/shell/modal/modal.service';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'silver-carousel',
  templateUrl: './carousel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input() carousel: CompAccess[];

  urlVideo: string = ''; // "https://player.vimeo.com/video/774797500?h=78d416ac67&amp;app_id=122963";
  mainTemplates: string[] = ['DST', 'DDB'];

  private readonly destroy$: Subject<void> = new Subject<void>();

  webcontents: WebContentResponse[] = [];
  title: string = '';

  constructor(
    private readonly config: NgbCarouselConfig,
    private readonly ngbModalService: NgbModal,
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly translationsService: TranslationsService,
    private readonly actionLogService: ActionLogService,
    private readonly utilsService: UtilsService,
    private readonly modalService: ModalService,
    private readonly translateService: TranslateService
  ) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }
  ngOnInit(): void {
    this.carousel.map((item: CompAccess) => {
      this.getContentInfoSubs(item.compContAccess[0].idLiferay)
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            console.log(err, item.compContAccess[0].idLiferay);
            this.manageError(err, item.compContAccess[0].idLiferay);
            throw new Error(err);
          })
        )
        .subscribe({
          next: (response: ContentProviderInterResponseDto) => {
            item['webcontent'] = response;
          },
          error: () => {}
        });
    });
  }

  openModal(content, slide) {
    //"https://vimeo.com/774797500/78d416ac67"
    this.title = slide.descrItem;
    const urlIdArray = slide.webcontent.bannerVideoUrl.split('/');
    this.setActionLog(slide);
    this.urlVideo = `https://player.vimeo.com/video/${urlIdArray[3]}?h=${urlIdArray[4]}&amp;app_id=122963`;
    this.ngbModalService.open(content, { centered: true, size: 'sm' });
  }

  goTo(slide): void {
    if (slide.webcontent.bannerUrl) {
      this.setActionLog(slide);
      this.navigate(slide.webcontent.bannerUrl);
    } else if (slide.webcontent.bannerDocumentName) {
      this.setActionLog(slide);
      this.openFile(slide.webcontent.id, 'BANNER');
    }
  }

  openContent(buttonNumber: number, webcontent: ContentProviderInterResponseDto): void {
    const documentName = webcontent[`buttonDocumentName${buttonNumber}`];
    const url = webcontent[`buttonUrl${buttonNumber}`];
    const document: 'BANNER' | 'BUTTON1' | 'BUTTON2' = this.getDocumentType(buttonNumber);
    if (url) {
      this.navigate(url);
      return;
    } else if (documentName) {
      this.openFile(webcontent.id, document);
    }
  }

  openFile(id: number, document: 'BANNER' | 'BUTTON1' | 'BUTTON2'): void {
    const params = {
      id,
      language: this.translationsService.getCurrentLanguage(),
      document
    };
    this.contentService.getDocument(params).subscribe((res) => {
      res['content'] && this.utilsService.openFile(res['content'], res['name'], res['type']);
    });
  }

  getContentInfoSubs(id: string): Observable<ContentProviderInterResponseDto> {
    const params = {
      contentName: id,
      language: this.translationsService.getCurrentLanguage()
    };
    return this.contentService.getContentProviderInter(params);
  }

  setActionLog(slice: any): void {
    const urlLength = slice.accessItem?.length;
    const actionLog: any = {
      action: 'CARRUSEL',
      certificateType: '',
      emplid: 'EMPTY',
      createdAt: new Date()
    };
    if (urlLength > 46) {
      actionLog.certificateType = `CARR:${slice.accessItem.slice(0, 46)}`;
    } else {
      actionLog.certificateType = `CARR:${slice.accessItem}`;
    }
    this.actionLogService.saveActionLog({ body: actionLog }).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDocumentType(button?: number): 'BANNER' | 'BUTTON1' | 'BUTTON2' {
    switch (button) {
      case 1:
        return 'BUTTON1';
      case 2:
        return 'BUTTON2';
      default:
        return 'BANNER';
    }
  }

  private navigate(url: string): void {
    url.includes('http://') || url.includes('https://', 0) ? window.open(url, '_blank') : this.router.navigate([url]);
  }

  private manageError(err: any, data: string): void {
    const errObj = err.error;
    const errCode = errObj.errorCode;
    const errorMessage = this.utilsService.errorsCatalogue(errCode);
    const message = errCode === 9007 ? this.translateService.instant(errorMessage, { 'data': data }) : errorMessage;
    this.modalService.triggerErrorModal('Error', message);
  }
}
