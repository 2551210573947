import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InitService } from '@core/services/init/init.service';
import cssVars from 'css-vars-ponyfill';
import { HeaderService } from '@shell/header/header.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppUrls } from '@config/app-urls.config';
import { Location } from '@angular/common';
import { LoginService } from '@core/services/login/login.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ModalService } from '@shell/modal/modal.service';
import { SecurityService } from '@devstack-angular/security';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  _destroyed$: Subject<void> = new Subject<void>();
  isPublicMode: boolean = null;
  lastLoginStatus: boolean = null;

  constructor(
    private readonly init: InitService,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly location: Location,
    public headerService: HeaderService,
    private readonly loginService: LoginService,
    private readonly utils: UtilsService,
    private readonly aroute: ActivatedRoute,
    private readonly modal: ModalService,
    private readonly securityService: SecurityService
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  async ngOnInit() {
    await this.init.init();

    // FIXME: temporary solution until a proper private/public mechanism is developed
    this.subscribeToRouteChanges();
    this.subscribeToLoadingHeader();
    this.checkLogoutAdviseAndHeader();

    cssVars({
      shadowDOM: true,
      include: 'style',
      onlyLegacy: false,
      watch: true
    });
  }

  /**
   * Metodo que se encarga de vigilar si se pierde la session
   */
  checkLogoutAdviseAndHeader() {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        })
      )
      .subscribe(() => {
        const force = this.utils.lookForAnyData(this.aroute.root, 'forcePublicHeader');
        const val = this.loginService.loginStatus.getValue();
        this.headerService.isPublicMode.next(!!(!val || force));
      });

    this.loginService.loginStatus.subscribe((val) => {
      const force = this.utils.lookForAnyData(this.aroute.root, 'forcePublicHeader');
      this.headerService.isPublicMode.next(!!(!val || force));
      if (this.lastLoginStatus === true && val === false) {
        this.headerService.isPublicMode.next(false);
        this.lastLoginStatus = val;
        if (this.loginService.expirationSubscribe) {
          this.securityService.unSubscribeExpireEvent();
          this.loginService.expirationSubscribe.unsubscribe();
          this.loginService.expirationSubscribe = null;
          (window as any).oneLogIn = undefined;
        }
        this.modal.triggerErrorModal('COMMON.LOGOUT.TITLE', 'COMMON.LOGOUT.DESC', AppUrls.AppPublicLogin + '/' + AppUrls.AppLogin, () => {
          this.headerService.isPublicMode.next(true);
        });
      } else {
        this.lastLoginStatus = val;
      }
    });
  }

  private subscribeToLoadingHeader(): void {
    this.headerService.isPublicMode.pipe(takeUntil(this._destroyed$), distinctUntilChanged()).subscribe((next) => {
      this.isPublicMode = next;
      this.cdRef.detectChanges();
    });
  }

  private subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroyed$)
      )
      .subscribe((event: NavigationEnd) => {
        const currentUrl = event.url.split('/')[1];
        const invalidRoute = this.isUrlInConfig(currentUrl);
        if (currentUrl === AppUrls.AppPublicLogin || invalidRoute) {
          this.headerService.isHeaderVisible.next(false);
          this.headerService.isPublicMode.next(true);
        } else {
          this.headerService.isHeaderVisible.next(true);
          this.headerService.isPublicMode.next(false);
        }
      });
  }

  private isUrlInConfig(currentUrl: string): boolean {
    return this.router.config.every((route) => route.path !== currentUrl);
  }
}
