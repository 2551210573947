<div class="category-container">
  <div class="category" *ngFor="let category of categories">
    <section class="header-container" (click)="goToCategoryPage(category.name)">
      <img src="{{ category.img }}" alt="Imagen de perfil" class="category-image" />
      <div class="header">
        <span class="header-tit">{{ category.name }}</span>
        <i class="icn-external-link"></i>
      </div>
    </section>
    <section class="questions-container">
      <div class="separator-line mt-1 mb-1"></div>
      <div class="subtitle">
        <span>Artículos más visitados</span>
      </div>
      <div class="questions" *ngFor="let question of category.questions">
        <span (click)="goToDetailPage(question)">{{ question }}</span>
      </div>
    </section>
  </div>
</div>
