<ngb-carousel>
  <ng-template ngbSlide *ngFor="let slide of carousel" [ngSwitch]="true">
    <div class="picsum-img-wrapper" *ngSwitchCase="slide.mainTemplate === 'DVD'">
      <img *ngIf="slide.webcontent" [src]="'data:image/jpg;base64,' + slide.webcontent.bannerImageContent" />
      <div class="carousel-video">
        <i class="icn-videotutoriales-level-1" (click)="openModal(content, slide)"></i>
      </div>
      <div class="container-overlay-video" *ngIf="slide.webcontent">
        <h2 class="title-overlay">{{ slide.webcontent.title }}</h2>
        <p class="subtitle-overlay" *ngIf="slide.webcontent.html">{{ slide.webcontent.html }}</p>
      </div>
    </div>
    <div
      class="picsum-img-wrapper"
      [ngClass]="{ 'pointer': slide.webcontent?.bannerUrl || slide.webcontent?.bannerDocumentName }"
      *ngSwitchCase="slide.mainTemplate !== 'DVD'"
      (click)="goTo(slide)"
    >
      <img *ngIf="slide.webcontent" [src]="'data:image/jpg;base64,' + slide.webcontent.bannerImageContent" [alt]="slide.webcontent.bannerImageName" />
      <div class="container-overlay" *ngIf="slide.webcontent">
        <h1 class="title-overlay">{{ slide.webcontent.title }}</h1>
        <p class="subtitle-overlay" *ngIf="slide.webcontent.html">{{ slide.webcontent.html }}</p>
        <ng-container *ngIf="!slide.webcontent.bannerDocumentName && !slide.webcontent.bannerUrl">
          <div class="container-button" *ngIf="slide.webcontent.buttonTitle1 || slide.webcontent.buttonTitle2">
            <button class="button-carousel" *ngIf="slide.webcontent.buttonTitle1" (click)="openContent(1, slide.webcontent)">
              {{ slide.webcontent.buttonTitle1 }}
            </button>
            <button class="button-carousel" *ngIf="slide.webcontent.buttonTitle2" (click)="openContent(2, slide.webcontent)">
              {{ slide.webcontent.buttonTitle2 }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ngb-carousel>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe
        width="100%"
        height="369px"
        [title]="title"
        class="embed-responsive-item"
        [src]="urlVideo | safe: 'resourceUrl'"
        allow="encrypted-media; fullscreen;"
      >
      </iframe>
    </div>
  </div>
</ng-template>
