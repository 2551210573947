import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'silver-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @Output() goToCategory: EventEmitter<string> = new EventEmitter();
  @Output() goToDetail: EventEmitter<string> = new EventEmitter();

  categories = [
    // {
    //   img: './assets/img/beneficios.png',
    //   name: 'Beneficios',
    //   questions: [
    //     '¿Cómo contrato un beneficio?',
    //     '¿Cómo consulto los beneficios que tengo contratados?',
    //     '¿Cómo cancelo un beneficio?',
    //     '¿Puedo contratar un beneficio para un familiar?',
    //     '¿Cuándo debo renovar un beneficio?'
    //   ]
    // },
    // {
    //   img: './assets/img/retribucion.png',
    //   name: 'Retribuciones',
    //   questions: [
    //     '¿Cómo contrato un beneficio?',
    //     '¿Cómo consulto los beneficios que tengo contratados?',
    //     '¿Cómo cancelo un beneficio?',
    //     '¿Puedo contratar un beneficio para un familiar?',
    //     '¿Cuándo debo renovar un beneficio?'
    //   ]
    // },
    // {
    //   img: './assets/img/certificados.png',
    //   name: 'Certificados',
    //   questions: [
    //     '¿Cómo contrato un beneficio?',
    //     '¿Cómo consulto los beneficios que tengo contratados?',
    //     '¿Cómo cancelo un beneficio?',
    //     '¿Puedo contratar un beneficio para un familiar?',
    //     '¿Cuándo debo renovar un beneficio?'
    //   ]
    // }
  ];

  constructor() {}

  ngOnInit(): void {}

  goToCategoryPage(name) {
    this.goToCategory.emit(name);
  }

  goToDetailPage(question) {
    this.goToDetail.emit(question);
  }
}
