import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'silver-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() inputValue?: string;
  @Output() searchValue: EventEmitter<string> = new EventEmitter();

  public searchForm: FormGroup = new FormGroup({ search: new FormControl() });

  constructor() {}

  ngOnInit(): void {
    this.searchForm.valueChanges.subscribe((value) => {
      value && this.searchValue.emit(value.search);
    });
    this.inputValue && this.searchForm.controls.search.setValue(this.inputValue);
  }
}
