import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'silver-faq-cards',
  templateUrl: './faq-cards.component.html',
  styleUrls: ['./faq-cards.component.scss']
})
export class FaqCardsComponent {
  @Output() goToDetail: EventEmitter<string> = new EventEmitter();
  @Input() questions = [];

  goToDetailPage(question) {
    this.goToDetail.emit(question);
  }
}
