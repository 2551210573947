import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Endpoint } from '@core/models/endpoint/endpoint.interface';
import { HttpRequest } from '@angular/common/http';
import { EnviromentService } from '../environment/environment.service';
import { Config } from '@app/core/models/envConfig/config.interface';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HttpService } from '@app/core/http/http.service';
import { TranslationsService } from '@core/services/translations/translations.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Commonly used utils and helpers
 */
export class UtilsService {
  private envApis;
  private envConfig: Config | any;
  private separators = {
    dash: '-',
    slash: '/',
    bracket: '{',
    closeBracket: '}',
    doubleSlash: '//',
    slashAndBracket: '/{',
    closeSlashAndBracket: '}/'
  };
  private extensionMap = {
    png: 'image/png',
    pdf: 'application/pdf',
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    excel: 'Poderes/xlsx'
  };
  private readonly blobTypeWord: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  private readonly blobTypeExcel: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(private readonly config: EnviromentService, private ts: TranslationsService, private readonly http: HttpService) {
    this.envConfig = this.config.get();
    if (this.envConfig.app) {
      this.envApis = this.envConfig.app.rest;
    }
  }

  getValidationMessages(): { type: any; message: any }[] {
    return [
      { type: 'required', message: this.ts.instant('FORM.REQUIRED') },
      { type: 'minlength', message: this.ts.instant('FORM.MIN_LENGTH') },
      { type: 'maxlength', message: this.ts.instant('FORM.MAX_LENGTH') },
      { type: 'patternEmail', message: this.ts.instant('FORM.PATTERN_EMAIL') },
      { type: 'patternPsw', message: this.ts.instant('FORM.PATTERN_PSW') },
      {
        type: 'patternBirthdate',
        message: this.ts.instant('FORM.PATTERN_BIRTHDATE')
      },
      { type: 'wrongNumber', message: this.ts.instant('FORM.WRONG_NUMBER') },
      { type: 'noPasswordMatch', message: this.ts.instant('FORM.PSW_EQUALS') },
      { type: 'wrongDoc', message: this.ts.instant('FORM.WRONG_DOC') },
      { type: 'noEmailMatch', message: this.ts.instant('FORM.EMAIL_EQUALS') },
      { type: 'noPhoneMatch', message: this.ts.instant('FORM.PHONE_EQUALS') },
      {
        type: 'validatePhoneNumber',
        message: this.ts.instant('FORM.WRONG_NUMBER')
      },
      {
        type: 'emailMaxlength',
        message: this.ts.instant('FORM.EMAIL_MAX_LENGTH')
      }
    ];
  }

  /**
   * Return the index of the element in arr which respect the predicate.
   * It returns -1 if no element is found.
   * @param arr
   * @param predicate
   */
  lastIndex = (arr, predicate) => arr.map((item) => predicate(item)).lastIndexOf(true);

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('psw').value;
    const confirmPass = group.get('pswRepeat').value;
    return pass === confirmPass ? null : { noPasswordMatch: true };
  };

  checkEmail: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const newEmail = group.get('newEmail').value;
    const repeatEmail = group.get('repeatEmail').value;
    return newEmail === repeatEmail ? null : { noEmailMatch: true };
  };

  checkPhone: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const newPhone = group.get('newPhone').value?.e164Number;
    const repeatPhone = group.get('repeatPhone').value?.e164Number;
    return newPhone === repeatPhone ? null : { noPhoneMatch: true };
  };

  extractDate(dateInput: string) {
    const dateSplitted = dateInput.split('-');
    const tempDate = new Date(parseInt(dateSplitted[0], 10), parseInt(dateSplitted[1], 10) - 1, parseInt(dateSplitted[2], 10));
    const monthsArray = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    const daysArray = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const translatedMonths = monthsArray.map((month) => {
      return this.ts.instant(`COMMON.MONTH.${month}`);
    });
    const translatedDays = daysArray.map((day) => {
      return this.ts.instant(`COMMON.DAY.${day}`);
    });

    const dayOfWeek = (date) => translatedDays[date.getDay()];
    const getMonthName = (date) => translatedMonths[date.getMonth()];

    return `${dayOfWeek(tempDate)} ${tempDate.getDate()} de ${getMonthName(tempDate)} ${tempDate.getFullYear()}`;
  }

  cleanObject = (obj) => {
    for (const propName in obj) {
      if ((obj.hasOwnProperty(propName) && obj[propName] === null) || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

  getMonthsArray(): string[] {
    const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    const translatedMonths = [];

    for (let i = 0; i < months.length; i++) {
      translatedMonths.push(this.ts.instant('COMMON.MONTHS.' + months[i]));
    }

    return translatedMonths;
  }

  /**
   * Helper method for matching a request with its endpoint config block
   * @param request intercepted
   */
  matchRequestEndpoint = (request: HttpRequest<any>) => {
    // FIXME: is not matching correctly the url params
    const requestUrl = this.cleanRequestUrl(request.url);
    this.envConfig = this.config.get();
    if (this.envConfig.app) {
      this.envApis = this.envConfig.app.rest;
    }
    const api = this.matchApi(requestUrl, this.envApis);
    const url = {
      baseUrl: api ? api.baseUrl : ''
    };
    const endpoints: Endpoint = api ? api.endpoints : {};
    let endpoint: string;
    for (endpoint in endpoints) {
      if (this.requestUrlComparer(endpoint, endpoints, url.baseUrl, requestUrl)) {
        return { ...endpoints[endpoint], ...url, ...{ name: endpoint } };
      }
    }
    return false;
  };

  convertDateToNgbDate(date: string): NgbDate {
    const dateObj: number[] = date.split('-').map((item) => parseInt(item, 10));
    return new NgbDate(dateObj[0], dateObj[1], dateObj[2]);
  }

  convertNgbDateToIsoString(ngbDate: NgbDate): string {
    const day = String(ngbDate.day).padStart(2, '0');
    const month = String(ngbDate.month).padStart(2, '0');
    const year = ngbDate.year;

    return `${year}-${month}-${day}`;
  }

  /*
      Return fullDate ready to datepicker, if month is less than 10, we add a 0 before.
   */
  getCurrentDate(): string {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const fullDate = `${year}-${month}-${day}`;
    const fullDateWithZero = `${year}-0${month}-${day}`;

    if (month < 10) {
      return fullDateWithZero;
    } else {
      return fullDate;
    }
  }

  /**
   * Helper method for retrieving required config for interceptors
   * @param endpoint contains specific endpoint info and config
   * @param interceptorName (Loader, HttpCustom and Retry)
   */
  getInterceptorConfig = (endpoint, interceptorName: string) => {
    const interceptorOptionsField = {
      Loader: 'loaderOptions',
      HttpCustom: 'restOptions',
      Retry: 'retryOptions'
    };
    // In case somebody wants to extend the HttpCustom interceptor capabilities, you can pass
    // more options by combining the httpCustomAddons object with the block configuration
    const httpCustomAddons = {
      customProp: ''
    };
    return interceptorName === 'HttpCustom'
      ? {
          ...httpCustomAddons,
          ...endpoint[interceptorOptionsField[interceptorName]]
        }
      : endpoint[interceptorOptionsField[interceptorName]];
  };

  openFile(data: string, fileName: string, type?: string) {
    const nav = window.navigator as any;
    let blob: any;
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    if (type === 'word') {
      blob = new Blob([byteArray], { type: this.blobTypeWord });
    } else if (type === 'excel') {
      blob = new Blob([byteArray], { type: this.blobTypeExcel });
    } else {
      blob = new Blob([byteArray], { type: this.extensionMap.pdf });
    }
    if (nav.msSaveOrOpenBlob) {
      // IE 11+
      nav.msSaveOrOpenBlob(blob, fileName);
    } else if (navigator.userAgent.match('FxiOS')) {
      // FF iOS
      console.error('Cannot display on FF iOS');
    } else if (navigator.userAgent.match('CriOS')) {
      // Chrome iOS
      const reader = new FileReader();
      reader.onloadend = function () {
        // window.open(reader.result);
      };
      reader.readAsDataURL(blob);
    } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
      // Safari & Opera iOS
      const url = window.URL.createObjectURL(blob);
      window.location.href = url;
    } else {
      const url = URL.createObjectURL(blob);
      // setTimeout(function() {
      //   // For Firefox it is necessary to delay revoking the ObjectURL
      //   window.URL.revokeObjectURL(url);
      // }, 100);
      // window.open(url, '_blank');

      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      type === 'application/pdf' && window.open(a.href, '_blank');
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  openBlob(blob, fileName) {
    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      // IE 11+
      nav.msSaveOrOpenBlob(blob, fileName);
    } else if (navigator.userAgent.match('FxiOS')) {
      // FF iOS
    } else if (navigator.userAgent.match('CriOS')) {
      // Chrome iOS
      const reader = new FileReader();
      reader.onloadend = function () {
        // window.open(reader.result);
      };
      reader.readAsDataURL(blob);
    } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
      // Safari & Opera iOS
      const url = window.URL.createObjectURL(blob);
      window.location.href = url;
    } else {
      const url = URL.createObjectURL(blob);
      // setTimeout(function() {
      //   // For Firefox it is necessary to delay revoking the ObjectURL
      //   window.URL.revokeObjectURL(url);
      // }, 100);
      // window.open(url, '_blank');

      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  convertBase64toBlob(data: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: this.extensionMap.pdf });
  }

  convertFileIdToBlob(data: string, type?: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    if (type === 'word') {
      return new Blob([byteArray], { type: this.blobTypeWord });
    } else if (type === 'excel') {
      return new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    } else {
      return new Blob([byteArray], { type: this.extensionMap.pdf });
    }
  }

  getRestMock(url: string): Observable<any> {
    return this.http.request('get', url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Catalog of errors for custom error mapping
   */
  errorsCatalogue(errorCode: string): string {
    const errors = this.envConfig.app.properties.errors;
    return errors[errorCode] || 'COMMON.ERROR.GENERIC';
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  openPDF(data: string, fileName: string, type?: string) {
    const nav = window.navigator as any;
    let blob: any;
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    if (type === 'word') {
      blob = new Blob([byteArray], { type: this.blobTypeWord });
    } else if (type === 'excel') {
      blob = new Blob([byteArray], { type: this.blobTypeExcel });
    } else {
      blob = new Blob([byteArray], { type: this.extensionMap.pdf });
    }
    if (nav.msSaveOrOpenBlob) {
      // IE 11+
      nav.msSaveOrOpenBlob(blob, fileName);
    } else if (navigator.userAgent.match('FxiOS')) {
      // FF iOS
    } else if (navigator.userAgent.match('CriOS')) {
      // Chrome iOS
      const reader = new FileReader();
      reader.onloadend = function () {
        // window.open(reader.result);
      };
      reader.readAsDataURL(blob);
    } else if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
      // Safari & Opera iOS
      const url = window.URL.createObjectURL(blob);
      window.location.href = url;
    } else {
      const url = URL.createObjectURL(blob);
      // setTimeout(function() {
      //   // For Firefox it is necessary to delay revoking the ObjectURL
      //   window.URL.revokeObjectURL(url);
      // }, 100);
      // window.open(url, '_blank');
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  lookForAnyData(routeRef: ActivatedRoute | ActivatedRouteSnapshot, dataRef: string): any {
    let data;
    if ((routeRef.data as any).getValue) {
      // es un BehaviourSubject
      data = (routeRef.data as BehaviorSubject<any>).getValue()[dataRef];
    } else {
      data = (routeRef.data as any)[dataRef];
    }
    if (data) {
      return data;
    } else {
      if (routeRef.children.length > 0) {
        let toReturn = null;
        routeRef.children.forEach((element) => {
          toReturn = toReturn || this.lookForAnyData(element, dataRef);
        });
        return toReturn;
      } else {
        return null;
      }
    }
  }

  formatDateToLocale(dateToFormat: string, local) {
    const date = new Date(dateToFormat);
    const format = (date, locale) =>
      new Intl.DateTimeFormat(locale, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(date);
    return format(date, local);
  }

  public uploadAttachment(file: { value: string; filename: string; filetype: any }): Blob {
    const blob = this.convertBase64toBlob(file.value);
    const ieblob = new Blob([blob], { type: file.filetype });
    ieblob['name'] = file.filename;
    return Object.assign(ieblob) as Blob;
  }

  /**
   * Checks the provided endpoint name containing variables to find out if the
   * request url matches it
   *
   * @param isVariable: boolean
   * @param endpUrl: string
   * @param baseUrl: string
   * @param reqUrl: string
   */
  private checkVariableEndpoints = (isVariable: boolean, endpUrl: string, baseUrl: string, reqUrl: string): boolean => {
    if (!isVariable) {
      return false;
    }

    // First split the urls into chunks
    // Special handling for urls starting with 'protocol://' <- we need to remove that part
    // but considering that it may be the baseUrl
    const endpUrlChunks = this.urlChunks(endpUrl, baseUrl);
    const reqUrlChunks = this.urlChunks(reqUrl, baseUrl);

    // If they're not the same length they're not a match
    if (endpUrlChunks.length !== reqUrlChunks.length) {
      return false;
    }

    // Now check how many fields and variables the url contains and their positions
    // and try to determine if the request url satisfies them
    // The main idea will be that 'some/{variable}/url/provided' will match 'some/doe/url/provided'
    // by comparing them removing the variable part and separators -> 'someurlprovided' === 'someurlprovided'
    while (endpUrlChunks.some(this.isVariable)) {
      const index = endpUrlChunks.findIndex(this.isVariable);
      endpUrlChunks.splice(index, 1);
      reqUrlChunks.splice(index, 1);
    }

    return endpUrlChunks.join('') === reqUrlChunks.join('') || baseUrl + endpUrlChunks.join('') === reqUrlChunks.join('');
  };

  /**
   * Removes possible parameters from request url so as to avoid issues on endpoint detection
   * @param requestUrl: string representing the original request url
   * @returns string: filtered url
   */
  private cleanRequestUrl = (requestUrl: string) => {
    const param = '?';
    return requestUrl.indexOf(param) !== -1 ? requestUrl.slice(0, requestUrl.indexOf(param)) : requestUrl;
  };

  /**
   * Returns endpoint check result against provided request url
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private compare = (endpoint: string, endpoints: Endpoint, baseUrl: string, requestUrl: string, variableEndpoint?: boolean) => {
    return endpoint
      ? endpoints[endpoint].url === requestUrl ||
          baseUrl + endpoints[endpoint].url === requestUrl ||
          requestUrl.indexOf(endpoints[endpoint].url) !== -1 ||
          requestUrl.indexOf(baseUrl + endpoints[endpoint].url) !== -1 ||
          this.checkVariableEndpoints(variableEndpoint, endpoints[endpoint].url, baseUrl, requestUrl)
      : false;
  };

  /**
   * Checks if provided string contains interpretable variables
   * @param fragment: string
   */
  private isVariable = (fragment: string): boolean => {
    const ind = '{';
    return fragment.indexOf(ind) !== -1;
  };

  /**
   * Searches for the api block containing the request endpoint and returns it
   * @param requestUrl: string
   * @param envApis: EnvRest api blocks
   * @return api: identified api block
   */
  private matchApi = (requestUrl: string, envApis) => {
    let api: string;
    const apis = envApis;
    for (api in apis) {
      if (this.requestUrlComparer('', apis[api].endpoints, apis[api].baseUrl, requestUrl)) {
        return apis[api];
      }
    }
    return false;
  };

  /**
   * Compares the request url against the available endpoints to find a match
   * @param endpoint: string
   * @param endpoints: Endpoint
   * @param baseUrl: string
   * @param requestUrl: string
   * @param variableEndpoint (optional): boolean
   */
  private requestUrlComparer = (endpoint: string, endpoints: Endpoint, baseUrl: string, requestUrl: string, variableEndpoint?: boolean) => {
    if (!endpoints || !Object.keys(endpoints).length) {
      return false;
    }
    // If no endpoint is provided means we're still looking for the api block, so we'll iterate through
    // all the endpoints for each api block until finding it
    if (!endpoint) {
      let endp: string;
      for (endp in endpoints) {
        if (this.requestUrlComparer(endp, endpoints, baseUrl, requestUrl, this.isVariable(endp))) {
          endpoint = endp;
          break;
        }
      }
    }
    return this.compare(endpoint, endpoints, baseUrl, requestUrl, variableEndpoint);
  };

  /**
   * Removes protocol part and returns the rest of the url as chunks
   * considering the char '/' as separator
   *
   * @param url: string
   * @param baseUrl (optional): string. Pending implementation
   */
  private urlChunks = (url: string, baseUrl?: string) => {
    return url.indexOf(this.separators.doubleSlash) !== -1
      ? url.split(this.separators.doubleSlash)[1].split(this.separators.slash)
      : url.split(this.separators.slash);
  };

  validarIbanCompleto(iban: string): boolean {
    const longitudIbanEspana = 24;
    const codigoPaisEspana = 'ES';

    if (iban.length !== longitudIbanEspana || iban.slice(0, 2) !== codigoPaisEspana) {
      return false;
    }

    // Mover los primeros 4 caracteres al final del IBAN
    const ibanReorganizado = iban.slice(4) + iban.slice(0, 4);

    // Convertir las letras en números (A=10, B=11, ..., Z=35)
    let ibanNumerico = '';
    for (let i = 0; i < ibanReorganizado.length; i++) {
      const caracter = ibanReorganizado[i];
      if (isNaN(Number(caracter))) {
        ibanNumerico += (caracter.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
      } else {
        ibanNumerico += caracter;
      }
    }

    // Realizar la división por 97 y comprobar si el resto es 1
    const resto = BigInt(ibanNumerico) % BigInt(97);
    return resto === BigInt(1);
  }

  /**
   * Cálculo IBAN
   * Requiere número de cuenta sin el IBAN
   *
   */
  calculoIBAN(accountBank: number | string): string {
    const countryCode = 'ES';
    const countryNumber = '142800';
    let iban = '';
    let digitControl = null;

    var ccc = accountBank.toString() + countryNumber;

    digitControl = 98 - this.modulo(ccc, 97);
    if (digitControl < 10) {
      digitControl = '0' + digitControl;
    }

    iban = countryCode + digitControl;
    return iban;
  }

  /**
   * Cálculo módulo 97 para IBAN
   * Requiere dividendo, divisor
   *
   */
  private modulo(divident: string, divisor: number) {
    var partLength = 10;

    while (divident.length > partLength) {
      const part = Number(divident.substring(0, partLength));
      divident = (part % divisor) + divident.substring(partLength);
    }

    return Number(divident) % divisor;
  }
}
